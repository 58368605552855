import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Api from '~/modules/Api'
import RecoverForm from '../widgets/RecoverForm'
import fullLogo from 'img/picsize.png'
import swal from 'sweetalert'
import 'custom/login.scss'

class Recover extends Component {
  constructor(props, context) {
    const storedMessage = localStorage.getItem('successMessage')
    super(props, context)

    if (storedMessage) {
      localStorage.removeItem('successMessage')
    }

    this.state = {
      user: {password: '', password_check: ''},
      loading: false,
      errorStatus: false
    }

    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async componentWillMount() {
    const {t: recover_token} = this.props.location.query
    if (!recover_token) {
      return window.location = './'
    }
    this.setState({loading: true})
    const check = await Api.post(`/account/recover?check=${recover_token}`)
    if (!check.isValid) {
      return window.location = './'
    }
    this.setState({loading: false})
  }

  componentDidMount() {
    document.body.classList.add('login')
  }

  handleChange(e) {
    const userState = this.state.user
    userState[e.target.name] = e.target.value
    this.setState({user: userState})
  }

  async processForm (event) {
    event.preventDefault()
    const {password: new_pwd, password_check} = this.state.user
    const {t: token} = this.props.location.query
    if (new_pwd.length < 6) {
      this.setState({errorStatus: 'A senha deve ter no mínimo 6 caracteres!'})
      return
    }
    if (new_pwd !== password_check) {
      this.setState({errorStatus: 'A senha digitada e a confirmação não coincidem!'})
      return
    }
    await Api.post('/account/recover', {
      token, new_pwd
    })
    await swal('Pronto', 'Senha atualizada!', 'success')
    return window.location = './'
  }

  render() {
    if (this.state.loading) return (<div />)
    return (
      <div>
        <div className="logo">
          <a href="http://painel.picsize.com.br/">
            <img src={fullLogo}  alt="PICSIZE" />
          </a>
        </div>,
        <div className="content">
          <RecoverForm
          onSubmit={this.processForm}
          errorStatus={this.state.errorStatus}
          inputs={this.state.user}
          handleChange={this.handleChange}
          />
        </div>
        <div className="copyright"> PICSIZE &copy; {(new Date()).getFullYear()}</div>
      </div>
    )
  }
}

Recover.contextTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default Recover
