import React, {Component} from 'react'

import {
  Input,
  Icon,
  Button,
  Form,
  Tooltip,
  Divider,
} from 'antd'

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 7}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12}
  },
  className: 'ps-ant-formItem'
}

class AccountDataForm extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isSubmiting: false,
      willEditPassword: false,

      validation_currentPassword: '',
      validation_newPassword: '',
      validation_newPasswordConfirmation: '',

      userName: '',
      displayName: '',
      newPassword: '',
      newPasswordConfirmation: '',
      currentPassword: '',
      ...this.props.details
    }

    this.onChange = this.onChange.bind(this)
    this.validateNewPassword = this.validateNewPassword.bind(this)
  }

  onChange (value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }
    this.setState({[key]: value})
  }

  onSubmit (e) {
    e.preventDefault()
    const data = {}

    this.setState({isSubmiting: true})

    if (this.state.willEditPassword) {
      if (!this.validateNewPassword()) return false
      data.currentPassword = this.state.currentPassword
      data.newPassword = this.state.newPassword
    }

    if (this.props.details.userName !== this.state.userName) {
      data.userName = this.state.userName
    }

    if (this.props.details.displayName !== this.state.displayName) {
      data.displayName = this.state.displayName
    }

    this
    .props
    .submit(data)
    .then((response) => {
      this.setState({
        isSubmiting: false,
        // willEditPassword: false,
        // validation_currentPassword: false,
        // validation_newPassword: false,
        // validation_newPasswordConfirmation: false,
        ...response
      })
    })
  }

  validateNewPassword () {
    const validation = {
      newPassword: false,
      newPasswordConfirmation: false
    }
    if (this.state.newPassword.length < 6) {
      validation.newPassword = {
        help: 'A senha deve ter ao menos 6 caracteres',
        validateStatus: 'error'
      }
    }
    if (this.state.newPasswordConfirmation !== this.state.newPassword) {
      validation.newPasswordConfirmation = {
        help: 'A confirmação de senha não coincide com a senha informada',
        validateStatus: 'error'
      }
    }

    if (validation.newPassword || validation.newPasswordConfirmation) {
      this.setState({
        isSubmiting: false,
        validation_newPassword: validation.newPassword,
        validation_newPasswordConfirmation: validation.newPasswordConfirmation
      })
      return false
    }

    return true
  }

  render() {
    const tooltipTitle = (<div style={{padding: '5px 10px', margin: 0}}>
        <p style={{padding: 0, margin: 0}}>
        Será o nome utilizado nos emails e páginas públicas para identificar o seu trabalho
        </p>
        <p style={{padding: 0, margin: 0}}>
        <strong>Exemplo:</strong>
        <br />"Studio {this.state.userName}"
        </p>
    </div>)
    return (
      <Form layout="horizontal" onSubmit={this.onSubmit.bind(this)}>
        <Divider orientation="left">Dados da conta</Divider>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              Nome de exibição&nbsp;
              <Tooltip title={tooltipTitle} placement='bottom'>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          <Input
          name="displayName"
          value={this.state.displayName}
          onChange={this.onChange}
          prefix={<Icon type="global" style={{color: 'rgba(0,0,0,.25)'}} />}
          />
        </Form.Item>
        <Divider orientation="left">Dados pessoais</Divider>
        <Form.Item label="Nome e sobrenome" {...formItemLayout}>
          <Input
            name="userName"
            value={this.state.userName}
            onChange={this.onChange}
            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />}
          />
        </Form.Item>
        <Form.Item label="E-mail" {...formItemLayout} wrapperCol={{xs: {span: 24}, sm: {span: 7}}}>
          <Input
            name="userEmail"
            prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />}
            disabled
            value={this.state.userEmail}
          />
        </Form.Item>
        <Form.Item
        label="Senha atual" {...formItemLayout}
        wrapperCol={{xs: {span: 24}, sm: {span: 10}}}
        {...this.state.validation_currentPassword}
        >
          <Input.Group compact>
            <Input
              type="password"
              style={{width: '70%'}}
              name="currentPassword"
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              disabled={!this.state.willEditPassword}
              placeholder={this.state.willEditPassword ? 'Sua senha atual' : '***********'}
              value={this.state.currentPassword}
              onChange={this.onChange}
            />
            {this.state.willEditPassword ? (
              <Button style={{width: '30%'}} type="danger" disabled={this.state.isSubmiting} onClick={() => {
                this.setState({
                  currentPassword: '',
                  willEditPassword: false,
                  newPassword: '',
                  newPasswordConfirmation: ''
                })
              }}>
                Cancelar
              </Button>
            ) : (
              <Button style={{width: '30%'}} type="primary" onClick={() => {
                this.setState({currentPassword: '', willEditPassword: true})
              }}>
                Alterar
              </Button>
            )}
          </Input.Group>
        </Form.Item>
        {this.state.willEditPassword && (
        <Form.Item label="Nova senha" {...formItemLayout}
            wrapperCol={{
              xs: {span: 24},
              sm: {span: 10}
            }}
            {...this.state.validation_newPassword}
          >
            <Input
                type="password"
                style={{width: '70%'}}
                name="newPassword"
                value={this.state.newPassword}
                onChange={this.onChange}
                placeholder="Sua nova senha"
                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              />
          </Form.Item>
        )}
        {this.state.willEditPassword && (
        <Form.Item {...formItemLayout}
            wrapperCol={{
              xs: {span: 24},
              sm: {span: 10, offset: 7}
            }}
            {...this.state.validation_newPasswordConfirmation}
          >
          <Input
                type="password"
                style={{width: '70%'}}
                name="newPasswordConfirmation"
                value={this.state.newPasswordConfirmation}
                onChange={this.onChange}
                placeholder="Confirmação de senha"
                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              />
          </Form.Item>
        )}
        <Divider />
        <Form.Item wrapperCol={{span: 4, offset: 20}}>
          <Button
            loading={this.state.isSubmiting}
            type="primary"
            htmlType="submit"
            style={{width: '100%'}}
          >
            Salvar dados
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default AccountDataForm
