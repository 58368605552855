import React from 'react'
import {Link} from 'react-router'

function LoginForm(props) {
  return (
    <form onSubmit={props.onSubmit}>
      <Alert active={props.errorStatus}/>
      <div className="form-group email optional user_email">
        <label
          className="control-label email optional">Email</label>
        <input
          className="form-control string email optional" type="text" onChange={props.handleChange} name="email"/>
      </div>
      <div className="form-group password optional user_password">
        <label
          className="control-label password optional"
          htmlFor="user_password">Senha</label>
        <input
          className="form-control password optional" type="password" onChange={props.handleChange} name="password"/>
      </div>
      <div className="form-actions">
        <div className="row">
          <div className="col-md-8">
            <label className="rememberme check mt-checkbox mt-checkbox-outline">
              <input name="remember" defaultChecked={true} type="checkbox" />
              Continuar conectado
              <span />
            </label>
          </div>
          <div className="col-md-4" style={{height: '45px'}}>
            <button
              type="submit"
              name="commit"
              className="btn signin uppercase pull-right">
              Entrar
            </button>
          </div>
          <div className="col-md-12">
            <a className="forget-passwor" onClick={props.recoverPassword}>
              Esqueceu sua senha?
            </a>
          </div>
          </div>
        </div>
        <div className="login-options auth-with">
          <h4>Ou entrar com</h4>
          <div id="auth-with-wrap"></div>
        </div>
        <div className="create-account uppercase">
          <Link to="/signup">
            <span>Criar conta! Teste grátis por 14 dias!</span>
          </Link>
        </div>

      </form>
  )
}

function Alert(props) {
  if (!props.active) return null
  return (
    <div className="alert alert-danger animated fadeIn" >
      E-mail ou senha inválidos.
    </div>
  )
}

export default LoginForm
