import React, {Component} from 'react'
import swal from 'sweetalert'
import PropTypes from 'prop-types'

import Loading from '~/widgets/Loading'
import PlanStatus from '~/widgets/PlanStatus'
import Api from '~/modules/Api'
import Auth from '~/modules/Auth'

import AccountDataForm from './Form'

import './style.scss'

class AccountInfo extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      collections: false,
      stats: false
    }

    this.getData = this.getData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    const stats = await Api.get('/customer/stats')
    const details = await Api.get('/account/details')
    if (stats && !details.error) {
      return this.setState({stats, details})
    }
  }

  handleChange (e) {
    const inputs = this.state.inputs
    inputs[e.target.name] = e.target.value
    this.setState({inputs})
  }

  async submit (data) {
    const response = await Api.post('/account/edit', data)

    if (response.exception) {
      await swal('Erro!', 'Não foi possível atualizar os dados', 'error')
      window.location.reload()
    }
    if (!response.success) {
      return response
    }

    const currentUserData = Auth.userData
    Auth.userData = {...currentUserData, name: response.userName}
    await swal('Pronto!', 'Dados atualizados', 'success')
    window.location.reload()
  }

  render () {
    if (!this.state.stats) return (<Loading />)
    return (
      <div className="row">
        <div className="col-md-4">
          <PlanStatus stats={this.state.stats} />
        </div>
        <div className="col-md-8">
          <div className="portlet light" style={{paddingBottom: '20px'}}>
            <div className="portlet-body form">
              <AccountDataForm
                details={this.state.details}
                submit={this.submit}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}


AccountInfo.contextTypes = {
  router: PropTypes.object.isRequired
}

export default AccountInfo
