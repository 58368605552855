import React from 'react'


function formatBytes(bytes, decimals) {
   if (bytes == 0) return '0 Bytes'
   let k = 1024,
       dm = decimals || 2,
       sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
       i = Math.floor(Math.log(bytes) / Math.log(k))
   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function parseStats (stats) {
  const {
    site: hasSite,
    sel_space: availableSpace,
    sel_max_pictures: maxPictures,
    sel_max_collections: maxCollections
  } = (stats.subscription || {}).limits
  const {
    sel_space: usedSpace,
    sel_pictures: picturesCount,
    sel_collections: collectionsCount
  } = stats.usage
  const limits = []
  limits.push({ name: 'Site', usage: hasSite ? 'Sim' : 'Não' })
  limits.push({ name: 'Seleção', usage: (availableSpace > 0) ? 'Sim' : 'Não' })
  if (availableSpace > 0) {
    limits.push({ name: 'Espaço em disco (seleção)', usage: `${formatBytes((+usedSpace) * 1024 * 1024, 2)} de ${formatBytes((+availableSpace) * 1024 * 1024, 2)}` })
    limits.push({ name: 'Coleções', usage: `${collectionsCount} de ${maxCollections || '\u221e'}` })
    limits.push({ name: 'Fotos em coleções', usage: `${picturesCount} de ${maxPictures || '\u221e'}` })
  }
  return limits
}
function EditAccountForm(props) {
  const status = props.stats ? parseStats(props.stats) : false
  return (
    <div className="portlet light portlet-fit ">
      <div className="portlet-title">
        <div className="caption">
          <i className=" icon-layers font-green" />
          <span className="caption-subject font-green bold uppercase">Plano atual</span>
          <div className="caption-desc font-grey-cascade">Detalhes do plano ativo no momento</div>
        </div>
      </div>
      <div className="portlet-body">
        {status ? <div className="mt-element-list">
          <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
            <ul>
              {status.map((f, i) => (
                <li className="mt-list-item" key={'feature-' + i}>
                  <div className="pull-right"> {f.usage}</div>
                  <div className="list-item-content" style={{ padding: '0px' }}>
                    <strong>
                      {f.name}
                    </strong>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div> : null
      }
      </div>
    </div>
  )
}

export default EditAccountForm
