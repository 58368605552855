import React, { Component } from 'react'
import PropTypes from 'prop-types'

import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import { Icon } from 'antd'

import Auth from '~/modules/Auth'
import logoPICSIZE from 'img/logo-picsize.png'

import TopMenu from './TopMenu'

class TopHeader extends Component {
  render() {
    return (
      <div className="page-header-inner">
        <a
          href="javascript:;"
          className="menu-toggler responsive-toggler"
          data-toggle="collapse"
          data-target=".navbar-collapse">
          <Icon type="bars" />
        </a>
        <div style={{ display: 'inline-block', float: 'left' }}>
          <img src={logoPICSIZE} style={{ padding: 12 }} />
        </div>
        <div className="page-actions">
        </div>
        <div className="page-top">
          <TrialNotice />
          <TopMenu />
        </div>
      </div>
    )
  }
}

function TrialNotice () {
  const { subscription } = Auth.userData
  const style = {
    display: 'inline-block',
    marginTop: '17.5px',
    marginLeft: '10px',
    padding: '5px 10px'
  }

  if (!subscription || !Auth.isTrial) return null

  if (Auth.isTrialExpired) {
    return (
      <div className="alert alert-danger  " style={style}>
        <strong>Atenção!</strong> O seu período de testes expirou! <a href="https://www.picsize.com.br/contato">Clique aqui</a> para entrar em contato
      </div>
    )
  }

  return (
    <div className="alert alert-warning" style={style}>
      <strong>Atenção!</strong> O seu período de testes termina em
        <strong> {differenceInCalendarDays(subscription.expiration_date, new Date())} dias</strong>
        <a href='http://pro.picsize.com.br/upstart' className="btn btn-sm btn-outline yellow"
          style={{ verticalAlign: 'baseline', padding: '1px 8px', marginLeft: '6px' }}>
          Ver Planos
        </a>
    </div>
  )
}

TopHeader.propTypes = {
  children: PropTypes.element
}

export default TopHeader
