import Main from '~/layouts/Main'
import Auth from '~/modules/Auth'

import LoginView from '~/pages/Login'
import RecoverView from '~/pages/Recover'
import ErrorView from '~/pages/Error'
import Signup from '~/pages/Signup'
import AccountInfo from '~/pages/AccountInfo'
import PlatformList from '~/pages/PlatformList'

import Support from '~/pages/Support'

const routes = {
  childRoutes: [
    {
      path: '/',
      component: Main,
      indexRoute: {onEnter: (nextState, replace) => replace('/platforms')},
      onEnter: (nextState, replace) => {
        if (!Auth.isAuthenticated) {
          return replace('/login')
        }
      },
      childRoutes: [
        {path: 'account/info', component: AccountInfo},
        {path: 'platforms', component: PlatformList},
        {path: 'support', component: Support},
        {
          path: '/logout',
          onEnter: (nextState, replace) => {
            Auth.logout()
          }
        }
      ]
    },
    {
      path: '/signup',
      component: Signup
    },
    {
      path: '/login',
      component: LoginView
    },
    {
      path: '/recover',
      component: RecoverView
    },
    {
      path: '/error',
      component: ErrorView
    },
    {
      path: '/error/:reason',
      component: ErrorView
    }
  ]
}

export default routes
