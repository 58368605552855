import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Router, browserHistory } from 'react-router'
import routes from './routes'
//
import 'jquery'
import 'bootstrap'

import 'font-awesome/css/font-awesome.css'
import 'simple-line-icons/simple-line-icons.min.css'
import 'styles/metronic/lib.scss'
import 'ladda-bootstrap/dist/ladda-themeless.min.css'
import 'styles/metronic/components-rounded.min.css'

import 'custom/widgets.scss'


function inIframe () {
  try {
      return window.self !== window.top
  } catch (e) {
      return true
  }
}

if (inIframe()) {
  window.top.location.href = window.location.href
}

ReactGA.initialize('UA-81145851-3')

browserHistory.listen((location) =>  {
  ReactGA.pageview(location.pathname)
  if (window.Conpass) {
    window.Conpass.routeChange()
  }
})

ReactDOM.render(
  <Router history={browserHistory}>{routes}</Router>,
  document.getElementById('root')
)
