import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Spin } from 'antd'

import Api from '~/modules/Api'
import Auth from '~/modules/Auth'

import Loading from '~/widgets/Loading'
import PlanStatus from '~/widgets/PlanStatus'

class PlatformList extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      collections: false,
      inputs: {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      stats: false
    }

    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    this.getData()
    window.fbq('track', 'PageView')
  }

  async getData () {
    const stats = await Api.get('/customer/stats')

    if (!stats) return
    const { subscription } = stats
    if (subscription.limits) {
      if (subscription.limits.sel && !subscription.limits.site) {
        return Auth.crossLogin('select')
      }
    }
    return this.setState({ stats })
  }

  openPlatform = async (id) => {
    if (id === 0) {
      return Auth.crossLogin('site')
    }
    if (id === 1) {
      return Auth.crossLogin('select')
    }
    if (id === 2) {
      return Auth.crossLogin('revise')
    }
  }

  isSolutionEnabled = (solution) => {
    const stats = this.state.stats

    if (!stats || !stats.subscription) {
      return false
    }

    const { limits } = stats.subscription

    return limits && !!limits[solution]
  }

  render () {
    const stats = this.state.stats

    return (
      <div className="row">
        {
          !stats ?
          <Loading /> :
          <div>
            <div className="col-md-4">
              <PlanStatus stats={stats} />
            </div>
            <div className="col-md-8">
              <div className="portlet light">
                <div className="portlet-title">
                  <div className="caption">
                    <i className=" icon-bulb"/>
                    <span className="caption-subject font-dark sbold uppercase">
                      Soluções
                    </span>
                  </div>
                </div>
                <div className="portlet-body form row">
                  <PlatformWidget
                    title="Seleção de fotos"
                    desc="Plataforma de seleção de fotos"
                    color={'red'}
                    enabled={this.isSolutionEnabled('sel')}
                    icon="check-square-o"
                    onClick={() => this.openPlatform(1)}
                    />
                  <PlatformWidget
                    title="Site Profissional"
                    desc="Plataforma de gerenciamento do site"
                    color={'green'}
                    enabled={this.isSolutionEnabled('site')}
                    icon="tv"
                    onClick={() => this.openPlatform(0)}
                    />
                  <PlatformWidget
                    title="Prova de álbuns"
                    desc="Plataforma de aprovação de álbuns"
                    color={'purple'}
                    enabled={this.isSolutionEnabled('rev')}
                    icon="book"
                    onClick={() => this.openPlatform(2)}
                    />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

class PlatformWidget extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.any,
    color: PropTypes.any,
    title: PropTypes.any,
    desc: PropTypes.any,
    enabled: PropTypes.any,
  }

  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoading: false
    }
  }

  onClick = async () => {
    this.setState({ isLoading: true })
    await this.props.onClick()
    this.setState({ isLoading: false })
  }

  render() {
    const { enabled, color, icon, title, desc } = this.props

    return (
      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{ marginTop: '10px' }}>
      <Spin spinning={this.state.isLoading}>
        <div className={`dashboard-stat ${enabled ? color : 'grey'}`} style={{ cursor: 'pointer' }} onClick={this.onClick}>
          <div className="visual" style={{ height: '150px' }}>
            <i className={`fa fa-${icon} fa-icon-medium`} />
          </div>
          <div className="details">
            <div className="number uppercase" style={{ textAlign: 'left' }}>
            {title}
            </div>
            <div className="desc">{desc}</div>
          </div>
          <a className="more" style={{ textAlign:'right' }}> Acessar
            <i className="m-icon-swapright m-icon-white" />
          </a>
        </div>
      </Spin>
      </div>
    )
  }
}


PlatformList.contextTypes = {
  router: PropTypes.object.isRequired
}

export default PlatformList
