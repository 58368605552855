import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Auth from '~/modules/Auth'
import {Link} from 'react-router'

const navigationMenu = [
  {
    route: '/platforms',
    defaultRoute: '/platforms',
    title: 'Plataformas',
    icon: 'fa fa-star'
  },
  {
    route: '/account',
    defaultRoute: '/account/info',
    title: 'Minha Conta',
    icon: 'fa fa-user',
    // subroutes: [
    // {
    //   route: '/account/info',
    //   title: ''
    // }]
  }
]

class Sidebar extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      navigationMenu
    }

    this.activeRoute = this.isActive.bind(this)

    // TODO: melhorar método de filtrar menu

    const {subscription} = Auth.userData
    if (!subscription) return
    if (subscription.limits && !subscription.limits.rev) {
      this.state.navigationMenu = navigationMenu.filter((p) => !p.route.includes('/album'))
    }
  }
  isActive (routeName) {
    return this.props.location.pathname.includes(routeName)
  }
  render () {
    return (
      <div className="page-sidebar-wrapper">
        <div className="page-sidebar navbar-collapse collapse">
          <ul
            className="page-sidebar-menu menu-fixed page-header-fixed page-sidebar-menu-hover-submenu page-sidebar-menu-closed"
            data-keep-expanded="false"
            data-auto-scroll="true"
            data-slide-speed={200}>
            {this.state.navigationMenu.map((mainItem, mainIndex) => (
              <li className={'nav-item ' + (this.isActive(mainItem.route) && 'active open')} key={mainIndex}>
                <Link to={mainItem.defaultRoute || mainItem.route} className="nav-link nav-toggle">
                  <i className={mainItem.icon} />
                  <span className="title main">{mainItem.title}</span>
                  {this.isActive(mainItem.route) && <span className="selected" />}
                  <span className="arrow" />
                </Link>
                {
                  !mainItem.subroutes ? null :
                  (
                    <ul className="sub-menu">
                      {mainItem.subroutes.map((subItem, subIndex) => (
                        <li key={`${mainIndex}${subIndex}`} className={'nav-item ' + (this.isActive(subItem.route) && 'active open')}>
                          <Link to={subItem.route} className="nav-link nav-toggle">
                            <span className="title">{subItem.title}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )
                }
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

Sidebar.propTypes = {
  location: PropTypes.object
}

export default Sidebar
