import Api from './Api'
import swal from 'sweetalert'
import ReactGA from 'react-ga'

const based64Encode = window.btoa
const based64Decode = window.atob

async function notPlanned () {
  const openPlans = await swal({
    dangerMode: true,
    text: 'O seu plano atual não inclui esta solução!',
    title: 'Fora do plano',
      icon: 'error',
      buttons: {
        cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true,
      },
      confirm: {
        text: 'Fazer upgrade',
        value: true,
        className: 'good',
        visible: true,
        closeModal: true
      }
    }
  })
  if (openPlans) {
    window.location.href = 'http://pro.picsize.com.br/upstart'
  }
}

async function overLimit () {
  const openPlans = await swal({
    dangerMode: true,
    text: 'Esta ação está fora do limite do seu plano atual! Faça um upgrade para poder continuar',
    title: 'Fora do plano',
      icon: 'error',
      buttons: {
        cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true,
      },
      confirm: {
        text: 'Fazer upgrade',
        value: true,
        className: 'good',
        visible: true,
        closeModal: true
      }
    }
  })
  if (openPlans) {
    window.location.href = 'http://pro.picsize.com.br/upstart'
  }
}

const getCurrentTS = () => Math.floor(+(new Date()) / 1000)

const Auth = {
  async tryLogin (email, password, cat) {
    const { success, user } = await Api.post('/login/authenticate', { email, password, cat })

    if (success) {
      if (user.subscription && !user.subscription.active) {
        await this.logout(false)
        return window.location = '/error/blocked'
      }

      this.userData = user
    }

    return { success, user }
  },
  async trySignup (name, email, password, plan, ll) {
    const result = await Api.post('/account/signup', { name, email, password, plan, ll })
    if (result.success) {
      this.userData = result.user
    }
    return result
  },
  async crossLogin (service) {
    const result = await Api.post('/login/cross', { service })
    if (!result.onPlan) {
      await notPlanned()
      return
    }
    window.location = result.url
  },
  async isUnderLimit (resourceKey, limitKey, willAdd) {
    const { subscription, usage } = await Api.get('/customer/stats')
    if (!subscription || !usage) return false

    const is = (+usage[resourceKey] + willAdd) <= +subscription.limits[limitKey]
    if (!is) overLimit()
    return is
  },
  async logout (redir = true) {
    localStorage.removeItem('auth-time')
    localStorage.removeItem('auth-userData')
    localStorage.removeItem('auth-lastRemoteCheck')
    await Api.get('/login/logout')
    if (!redir) return true
    return window.location = './'
  },
  async checkSession () {
    localStorage.setItem('auth-lastRemoteCheck', getCurrentTS())
    Api
    .get('/customer/details')
    .then(async ({ subscription }) => {
      if (!subscription || subscription.active) {
        this.userData = {
          ...this.userData,
          subscription
        }
        return
      }
      await this.logout(false)

      return window.location = '/error/blocked'
    })
    .catch(() => this.logout())
  },
  get lastSessionCheck () {
    return (+localStorage.getItem('auth-lastRemoteCheck') || 0)
  },

  get isAuthenticated () {
    // remote check every 5 minutes
    if (this.lastSessionCheck && (getCurrentTS() - this.lastSessionCheck) > (60 * 5)) {
      this.checkSession()
    }

    const is = localStorage.getItem('auth-time') !== null && !!this.userData

    if (is) {
      this.trackUser()
    }

    return is
  },
  trackUser () {
    if (window.isTrackingLoaded) return

    const { name, email, customer_id, subscription } = this.userData

    ReactGA.set({ userId: customer_id })

    if (!this.isPersonificated) {
      window.enableHotjar()
    }

    if (window.Conpass) {
      window.Conpass.init({
        name, email,
        custom_fields: {
          customer_id,
          ...(!!subscription && ({
            activation_date: subscription.activation_date,
            active: subscription.active,
            expired: subscription.expired,
            plan_ids: subscription.plan_ids,
            trial: subscription.trial,
          }))
        }
      })
    }

    window.isTrackingLoaded = true
  },
  get isTrial () {
    const { subscription } = this.userData

    if (!subscription || !subscription.trial || !subscription.expiration_date) {
      return false
    }

    return true
  },
  get isTrialExpired () {
    const { subscription } = this.userData
    return (subscription && this.isTrial && subscription.expired)
  },
  get isPersonificated () {
    // é "true" quando o login é feito através do painel de admin
    return this.userData && this.userData.personificated
  },
  get userData () {
    const encodedData = localStorage.getItem('auth-userData')

    if (!encodedData || based64Decode(encodedData) === 'undefined') {
      return false
    }

    return JSON.parse(decodeURIComponent(based64Decode(encodedData)))
  },
  set userData (val) {
    localStorage.setItem('auth-time', getCurrentTS())
    localStorage.setItem('auth-userData', based64Encode(encodeURIComponent(JSON.stringify(val))))
    localStorage.setItem('auth-lastRemoteCheck', getCurrentTS())
  }
}

export default Auth
