import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Auth from '~/modules/Auth'
import 'custom/error.scss'

class Error extends Component {

  async componentWillMount() {
  }

  componentDidMount() {
    document.body.classList.add('page-404-full-page')
  }

  render() {
    const reason = (+this.props.params.reason) || 500
    return (
      <div className="row">
        <div className="col-md-12 page-404">
          <div className="number font-red" style={reason !== 404 ? {top: '-10px'} : {}}>
            {reason}
          </div>
          <div className="details">
            <h3>
            Ops... erro!
            </h3>
            <ErrorDetails reason={reason} />
          </div>
        </div>
      </div>
    )
  }
}

function ErrorDetails ({reason}) {
  const Anchor = () => (<span><a href="/" style={{'color': '#;'}}>Clique aqui</a> para voltar à página inicial.</span>)
  if (reason === 404) {
    return (
      <p style={{marginTop: '0px', lineHeight: '28px'}}>
        Não encontramos a página que você estava procurando.
        <br />
        <Anchor />
      </p>
    )
  }
  return (
    <p>
      Algo inesperado ocorreu durante processamento da sua requisição.
      <br />
      Os detalhes do erro foram salvos e enviados à nossa equipe técnica.
      <br />
      <br />
      <Anchor />
    </p>
  )
}

Error.contextTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default Error
