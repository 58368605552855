import Auth from './Auth'
import $ from 'jquery/src/core'
import 'jquery/src/ajax'
import 'jquery/src/ajax/xhr'

const apiUrl = process.env.API_URL

function Api () {
  const xhrPool = []

  return {
    get, post, del, upload, abortUploads
  }

  function abortUploads () {
    $.each(xhrPool, (idx, jqXHR) => {
      jqXHR.abort()
    })
  }

  function get (path) {
    return new Promise((resolve) => {
      $.ajax(`${apiUrl}${path}`, {
         method: 'GET',
         crossDomain: true,
         xhrFields: {
           withCredentials: true
         },
         success: resolve,
         error: (error) => {
           if (error.status === 403) return Auth.logout()
           resolve({ error })
         }
      })
    })
  }

  function post (path, data) {
    return new Promise((resolve) => {
      $.ajax(`${apiUrl}${path}`, {
         method: 'POST',
         data,
         crossDomain: true,
         xhrFields: {
           withCredentials: true
         },
         success: resolve,
         error: (error) => {
           if (error.status === 403) return Auth.logout()
           resolve({ error })
         }
      })
    })
  }


  function upload (path, params, files, onProgress) {
    const formData = new FormData()
    for (let file of files) {
      formData.append('images', file)
    }
    for (let param in params) {
      formData.append(param, params[param])
    }
    return new Promise((resolve) => {
      $.ajax(`${apiUrl}${path}`, {
         method: 'POST',
         data: formData,
         crossDomain: true,
         beforeSend: (jqXHR, settings) => {
           xhrPool.push(jqXHR)
         },
         xhr: () => {
           const myXhr = $.ajaxSettings.xhr()
           if (myXhr.upload) {
             myXhr.upload.addEventListener('progress', (e) => {
               if (!e.lengthComputable || !onProgress) return false
               const max = e.total
               const current = e.loaded
               return onProgress(current, current / max)
             }, false)
           }
           return myXhr
        },
         xhrFields: {
           withCredentials: true
         },
         contentType: false,
         processData: false,
         success: resolve,
         error: (error) => {
           if (error.status === 403) return Auth.logout()
           resolve({ error })
         }
      })
    })
  }

  function del (path) {
    return new Promise((resolve) => {
      $.ajax(`${apiUrl}${path}`, {
         method: 'DELETE',
         crossDomain: true,
         xhrFields: {
           withCredentials: true
         },
         success: resolve,
         error: (error) => {
           if (error.status === 403) return Auth.logout()
           resolve({ error })
         }
      })
    })
  }
}

export default Api()
