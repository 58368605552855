import React, { Component } from 'react'

import Auth from '~/modules/Auth'

import './style.scss'

const ContactIcon = () => (
<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="28" height="28">
  <path d="M636.014 556.58c9.728 0 102.839 48.567 106.861 55.443 1.134 2.852 1.134 6.29 1.134 8.558 0 14.299-4.571 30.28-9.728 43.446-13.129 32-66.304 52.554-98.852 52.554-27.429 0-84.005-23.991-108.581-35.438-81.7-37.157-132.571-100.572-181.723-173.13-21.724-32-41.143-71.423-40.558-110.847v-4.572c1.134-37.705 14.848-64.585 42.276-90.295 8.558-8.009 17.701-12.58 29.696-12.58 6.84 0 13.715 1.719 21.139 1.719 15.433 0 18.285 4.571 23.99 19.42 3.987 9.727 33.134 87.441 33.134 93.147 0 21.723-39.424 46.299-39.424 59.428 0 2.853 1.134 5.705 2.853 8.558 12.58 26.843 36.571 57.71 58.295 78.3 26.295 25.16 54.272 41.727 86.272 57.709a24.905 24.905 0 0 0 12.58 3.986c17.152 0 45.715-55.442 60.563-55.442zM520.009 859.43c197.705 0 358.839-161.134 358.839-358.84S717.714 141.752 520.009 141.752 161.17 302.885 161.17 500.59c0 75.447 23.991 149.138 68.572 210.285l-45.13 133.157 138.277-43.995a361.143 361.143 0 0 0 197.157 59.428zm0-789.724c237.714 0 430.848 193.134 430.848 430.848S757.723 931.401 520.01 931.401c-72.558 0-144.567-18.286-208.567-53.723l-238.3 76.58 77.715-231.424a428.91 428.91 0 0 1-61.696-222.28c0-237.715 193.134-430.849 430.848-430.849z" fill="#fff"/>
</svg>
)


export class ContactButton extends Component {
  onClick = () => {
    window.open(Auth.isTrial ? 'https://whts.co/picsizesuportetrial' : 'https://whts.co/picsizesuporte')
  }

  render() {
    return (
      <div className="w-contact-button" onClick={this.onClick}>
        <span>
          <ContactIcon />
        </span>
        <span>
          Suporte
        </span>
      </div>
    )
  }
}

export default ContactButton
